import ProjectHeader from "./components/ProjectHeader";
import Footer from "./components/Footer";
import ProjectAbout from "./components/ProjectAbout";
import { useEffect, useState } from "react";
import $ from "jquery";

const MyQuestList = () => {
  const [projectData, setProjectData] = useState({});
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const loadProjectData = () => {
      $.ajax({
        url: `my_quest_list_data.json`,
        dataType: "json",
        cache: false,
        success: function (data) {
          setProjectData(data);
          document.title = `${data.basic_info.name}`;
        },
        error: function (xhr, status, err) {
          alert(err);
        },
      });
    }
    loadProjectData();
    console.log("Test");
    document
      .getElementById(window.$primaryLanguageIconId)
      .setAttribute("filter", "brightness(40%)");
  }, [])

  const swapCurrentlyActiveLanguage = (oppositeLangIconId) => {
    var pickedLangIconId =
      oppositeLangIconId === window.$primaryLanguageIconId
        ? window.$secondaryLanguageIconId
        : window.$primaryLanguageIconId;
    document
      .getElementById(oppositeLangIconId)
      .removeAttribute("filter", "brightness(40%)");
    document
      .getElementById(pickedLangIconId)
      .setAttribute("filter", "brightness(40%)");
  }

  const applyPickedLanguage = (pickedLanguage, oppositeLangIconId) => {
    swapCurrentlyActiveLanguage(oppositeLangIconId);
    document.documentElement.lang = pickedLanguage;
    document.documentElement.lang === window.$primaryLanguage
        ? setLanguage("en")
        : setLanguage("it");
  }

  // return <h1>My Quest List</h1>;
  return (
    <div>
      <ProjectHeader sharedData={projectData.basic_info} />
      <div className="col-md-12 mx-auto text-center language">
        <div
          onClick={() =>
            applyPickedLanguage(
              window.$primaryLanguage,
              window.$secondaryLanguageIconId
            )
          }
          style={{ display: "inline", marginRight: "10px" }}
        >
          <span
            className="iconify language-icon mr-5"
            data-icon="twemoji-flag-for-flag-united-kingdom"
            data-inline="false"
            id={window.$primaryLanguageIconId}
          ></span>
        </div>
        <div
          onClick={() =>
            applyPickedLanguage(
              window.$secondaryLanguage,
              window.$primaryLanguageIconId
            )
          }
          style={{ display: "inline" }}
        >
          <span
            className="iconify language-icon"
            data-icon="twemoji-flag-for-flag-italy"
            data-inline="false"
            id={window.$secondaryLanguageIconId}
          ></span>
        </div>
      </div>
      <ProjectAbout
        language={language}
        sharedBasicInfo={projectData.basic_info}
      />
          <div id="skills" className="row center mx-auto">
            <div className="col-md-4 mb-5 center">
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <a style={{fontSize: "135%"}} href="/myquestlist/privacypolicy">Privacy policy</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      <Footer sharedBasicInfo={projectData.basic_info} />
    </div>
  )
};
  
export default MyQuestList;
  