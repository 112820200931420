import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import MyQuestList from './MyQuestList';
import MyQuestListPrivacyPolicy from './MyQuestListPrivacyPolicy';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* GLOBAL VARIABLES */

window.$primaryLanguage = 'en';
window.$secondaryLanguage = 'pl';
window.$primaryLanguageIconId = 'primary-lang-icon';
window.$secondaryLanguageIconId = 'secondary-lang-icon';

export default function Router() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/myquestlist" element={<MyQuestList />} />
          <Route path="/myquestlist/privacypolicy" element={<MyQuestListPrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    );
  }

ReactDOM.render(<Router />, document.getElementById('root'));
serviceWorker.register();
