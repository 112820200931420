import React, { Component } from "react";

class Skills extends Component {
  render() {
    if (this.props.sharedSkills && this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.skills;
      var hardFrontendSkills = this.props.sharedSkills.hard.frontend.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i} style={{ verticalAlign: "top" }}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
      var hardMobileSkills = this.props.sharedSkills.hard.mobile.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i} style={{ verticalAlign: "top" }}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
      var hardBackendSkills = this.props.sharedSkills.hard.backend.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i} style={{ verticalAlign: "top" }}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
      var hardDatabaseSkills = this.props.sharedSkills.hard.database.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i} style={{ verticalAlign: "top" }}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
      var hardDevopsSkills = this.props.sharedSkills.hard.devops.map(function (skills, i) {
        return (
          <li className="list-inline-item mx-3" key={i} style={{ verticalAlign: "top" }}>
            <span>
              <div className="text-center skills-tile">
                <i className={skills.class} style={{ fontSize: "220%" }}>
                  <p
                    className="text-center"
                    style={{ fontSize: "30%", marginTop: "4px" }}
                  >
                    {skills.name}
                  </p>
                </i>
              </div>
            </span>
          </li>
        );
      });
    }

    return (
      <section id="skills">
        <div className="col-md-12">
          <div className="col-md-12">
            <h1 className="section-title">
              <span className="text-white">{sectionName}</span>
            </h1>
          </div>
          <div className="col-md-12 text-center">
            <h2 className="section-subtitle">
              <span className="text-white">Hard skills</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4 mx-auto">
              <h2 className="section-subtitle">
                <span className="text-white">Frontend</span>
              </h2>
              <ul className="list-inline mx-auto skill-icon text-white">{hardFrontendSkills}</ul>
            </div>
            <div className="col-md-4 mx-auto">
              <h2 className="section-subtitle">
                <span className="text-white">Mobile</span>
              </h2>
              <ul className="list-inline mx-auto skill-icon text-white">{hardMobileSkills}</ul>
            </div>
            <div className="col-md-4 mx-auto">
              <h2 className="section-subtitle">
                <span className="text-white">Backend</span>
              </h2>
              <ul className="list-inline mx-auto skill-icon text-white">{hardBackendSkills}</ul>
            </div>
            <div className="col-md-4 mx-auto">
              <h2 className="section-subtitle">
                <span className="text-white">Database</span>
              </h2>
              <ul className="list-inline mx-auto skill-icon text-white">{hardDatabaseSkills}</ul>
            </div>
            <div className="col-md-4 mx-auto">
              <h2 className="section-subtitle">
                <span className="text-white">Devops</span>
              </h2>
              <ul className="list-inline mx-auto skill-icon text-white">{hardDevopsSkills}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
