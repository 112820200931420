import React, { Component } from "react";

class ProjectAbout extends Component {
  render() {
    if (this.props.sharedBasicInfo) {
      var profilepic = "images/" + this.props.sharedBasicInfo.image;
      console.log(profilepic);
    }
    if (this.props.sharedBasicInfo && this.props.language) {
      var hello = this.props.sharedBasicInfo.description_header_en;
      var about = this.props.sharedBasicInfo.description_en;
      if(this.props.language === "it"){
        hello = this.props.sharedBasicInfo.description_header_it;
        about = this.props.sharedBasicInfo.description_it;
      }
    }

    return (
      <section id="about">
        <div className="col-md-12">
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="250px"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card" style={{marginTop: 50}}>
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                      padding: "20px"
                    }}
                  >
                    <br />
                    <span className="wave">{hello}</span>
                    <br />
                    <br />
                    <pre style={{fontSize: "100%", whiteSpace: "pre-wrap"}}>{about}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ProjectAbout;
